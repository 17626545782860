import './style.scss';

import Glide from '@glidejs/glide'
new Glide('.glide', {
  type: 'carousel',
  startAt: 0,
  perView: 5,
  // autoplay: 5000,
  gap: 16,
  breakpoints: {
    992: {
      perView: 4
    },
    768: {
      perView: 3
    },
    576: {
      perView: 2
    }
  }
}).mount()

import AOS from 'aos';
AOS.init();
